:root {
  --black: #000000;
  --red: #cc0000;
  --green: #4e9a06;
  --yellow: #c4a000;
  --blue: #729fcf;
  --magenta: #75507b;
  --cyan: #06989a;
  --white: #d3d7cf;
  --brightBlack: #555753;
  --brightRed: #ef2929;
  --brightGreen: #8ae234;
  --brightYellow: #fce94f;
  --brightBlue: #32afff;
  --brightMagenta: #ad7fa8;
  --brightCyan: #34e2e2;
  --brightWhite: #fdfdfd;
}
